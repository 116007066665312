
@import 'variables';

// Common Navigation
    .list li { border-bottom: 1px $bdr-clr solid; list-style: none;  }
    .list li a {display: block; padding: 15px; text-decoration: none; color: $primary;  }
    .list li a:hover { background-color: $grey-3}
    .list li a.active { background-color: rgba($grey-3,0.3);}

header {
    .navi li { display: inline-block; border: none;}
    .navi li a {padding: 5px 8px;}
    .navi li a:hover { background-color: rgba($white,0.2); }
}
    .bg-primary a { color: white;}

.dropdown { min-width: 100%; border: 1px $bdr-clr solid; box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15); z-index: 5;
}
