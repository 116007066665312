@import 'variables';
@import 'common';

//Popup Styles

.overlay { background-color: rgba($black, 0.6); position:fixed; width:100%; height:100%; top:0; left:0; z-index:100;}
    .popup { @extend .bdr-rad; background-color:white; @extend .centermiddle; overflow: hidden;}
    .pophead { background-color:$primary; color:white;}
    .popcontent { max-height: 80vh; overflow: auto; }

    .close { width: 24px; height: 24px; @extend .rightmiddle; cursor: pointer;
        &:before, &:after { content:""; position: absolute; height: 4px; width: 24px; background-color: $white;
            top:12px; left: 0; @extend .bdr-rad;}
        &:before { transform: rotate(-45deg); }
        &:after { transform: rotate(45deg); }
    }
