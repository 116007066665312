/*Layout and columns*/

@import 'variables';


.flex-row { display: flex; }

    .col-is-10 {width:10%}
    .col-is-20 {width:20%}
    .col-is-30 {width:30%}
    .col-is-40 {width:40%}
    .col-is-50 {width:50%}
    .col-is-60 {width:60%}
    .col-is-70 {width:70%}
    .col-is-80 {width:80%}
    .col-is-90 {width:90%}

    .col-is-5 {width:5%}
    .col-is-15 {width:15%}
    .col-is-25 {width:25%}
    .col-is-35 {width:35%}
    .col-is-45 {width:45%}
    .col-is-55 {width:55%}
    .col-is-65 {width:65%}
    .col-is-75 {width:75%}
    .col-is-85 {width:85%}
    .col-is-95 {width:95%}
    .col-is-33 {width:33.33%}

    .col-is-flex { flex: 1;}

//Alignments

    .aligns-top { align-items: start;}
    .aligns-middle { align-items: center;}
    .aligns-bottom { align-items: flex-end;}

//Self Aligns
    .goes-top { align-self: flex-start;}
    .goes-center { align-self: center;}
    .goes-bottom { align-self: flex-end;}

//Positions
    .float-middle { justify-content:center;}
    .float-right { justify-content:flex-end;}

    .sp-justify {justify-content: space-between;}
    .sp-around {justify-content: space-around;}
    .sp-evenly {justify-content: space-evenly;}


//Flex cols
    .flex-row.columns { flex-direction: column; min-height: 100%;
        .is-flex { flex-grow: 1;}
    }


//Fixed widths

    .w300 { width: 300px; max-width: 80%}
    .w350 { width: 350px; max-width: 80%}
    .w400 { width: 400px; max-width: 80%}
    .w450 { width: 450px; max-width: 80%}
    .w500 { width: 500px; max-width: 80%}
    .w550 { width: 550px; max-width: 80%}
    .w600 { width: 600px; max-width: 80%}
    .w650 { width: 650px; max-width: 80%}
    .w700 { width: 700px; max-width: 80%}
    .w750 { width: 750px; max-width: 80%}
    .w800 { width: 800px; max-width: 80%}

//Page Layouts

    .master { width: 100%; min-height: 100vh; box-shadow: 0 0 0 10px rgba($grey-1, 1) inset;}
    .page-container { margin: auto; width: 95%; max-width: $max-width;}

// .flex-row > div { border: 1px red solid;}
