@import 'variables';

.header {
  background: $primary; /* Old browsers */
  background: linear-gradient(135deg, #ffffff 0%, $secondary 100%);
  color: $white;
  padding: 0 30px;
  height: 80px;
  border-bottom: 1px $white solid;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  a {
    color: $white;
  }
}

.logo {
  padding-right: 30px;
  margin-right: 30px;
  border-right: 1px $bdr-clr solid;
  // width: 144px;
  // height: 44px;
}

.header-title {
  color: $primary;
}
