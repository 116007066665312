@import 'variables';

.page-wrapper {
    box-sizing: border-box;
    height: calc(100vh - 82px);
    padding: 30px;

    .ag-header{border:none;}
    .ag-root-wrapper.ag-layout-normal {border:none;}
    .ag-root-wrapper-body .ag-header {background-color: rgba($tertiary, 0.3);}
    .ag-header .ag-header-cell-moving {background-color: rgba($tertiary, 0.8);}

}

.avtr { border-radius: 50%; box-shadow: 0 0 0 1px rgba($white, 0.5);}

.login-wrapper {
    display: grid;
    grid-template-columns: 30% 70%;
    background-image: url(../images/bitmap.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 30% 70%;
    height: 100vh;

    .login-column, .description-wrap{
        @extend .isflex;
        @extend .align-center;
    }
    .login-column{
        background-color: #fff;
    }
    .description-wrap{
        background: rgba(26,47,90,0.3);
    }
    .content-middle{
        margin: auto;
    }
    .psw{
        a{
            @extend .f14;
            color: $dark-grey;
        }
    }
}