$primary    : #1A2F5A;
$secondary  : #369AC8;
$tertiary   : #44B8BB;

$grey-1     : #D1D1D1;
$grey-2     : #CACACA;
$grey-3     : #F1F1F1;

$red        : #C1283B;

$text       : #646464;
$text-2     : #878787;

$page-bg    : #F1F1F1;
$bdr-clr    : #CACACA;
$bdr-grey    : #979797;

$black      : #000000;
$white      : #FFFFFF;
$dark-grey  : #686868;

$max-width  : 1200px;

